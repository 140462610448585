<template>
  <div>
    <b-navbar>
      <template slot="brand">
        <b-navbar-item tag="router-link" :to="{ path: '/forum' }">
          论坛
        </b-navbar-item>
      </template>
      <template slot="start">
        <b-navbar-item tag="router-link" :to="{ path: '/AllGallery' }">
          画廊
        </b-navbar-item>
        <b-navbar-dropdown label="Info">
          <b-navbar-item href="#"> About </b-navbar-item>
          <b-navbar-item href="#"> Contact </b-navbar-item>
        </b-navbar-dropdown>
      </template>
    </b-navbar>
    <!-- https://www.npmjs.com/package/vue-upload-multiple-image -->
    <section class="hero is-primary">
      <div class="hero-body">
        <div class="container">
          <h1 class="title">上传图集</h1>
          <h2 class="subtitle">请来一份色图</h2>
        </div>
      </div>
    </section>
    <center style="padding: 50px">
      <!-- <section style="margin-top: 4%;transform: scale(1,1);-ms-transform: scale(1,1);-webkit-transform: scale(1,1);"> -->
      <b-field label="Title" label-position="on-border">
        <b-input value="" v-model="title"></b-input>
      </b-field>
      <b-field grouped >
        <b-field label-position="on-border" label="贴标签" expanded>
          <b-taginput
            v-model="tags"
            :data="filteredTags"
            autocomplete
            :allow-new="true"
            :open-on-focus="true"
            field="name"
            icon="label"
            type="is-warning"
            placeholder="Add a tag"
          >
          </b-taginput>
        </b-field>
        <b-field
          message="不填即关闭此功能"
          label="访客访问密码"
          label-position="on-border"
        >
          <b-input value="" v-model="token"></b-input>
        </b-field>
      </b-field>
      <b-field label="Message" label-position="on-border">
        <b-input
          minlength="10"
          maxlength="200"
          type="textarea"
          style="max-height: 120px"
          v-model="text"
        ></b-input>
      </b-field>
      <vue-upload-multiple-image
        @upload-success="uploadImageSuccess"
        @before-remove="beforeRemove"
        @edit-image="editImage"
        :data-images="images"
        idUpload="myIdUpload"
        editUpload="myIdEdit"
        dragText="拖进来"
        browseText="也可以戳"
        primaryText="已选择为主"
        popupText="标记为封面"
        markIsPrimaryText="标记为主"
      ></vue-upload-multiple-image>

      <!-- </section> -->

      <b-button
        icon-left="upload"
        type="is-primary"
        @click="upload"
        style="position: fixed; bottom: 50px; right: 50px; z-index: 2000"
        >提交</b-button
      >
    </center>
  </div>
</template>

<script>
import VueUploadMultipleImage from "vue-upload-multiple-image";
import Compressor from "compressorjs";

export default {
  data() {
    return {
      images: [],
      files: [],
      uploaded: {},
      filteredTags: ["test", "RBQ"],
      token: "",
      text: "",
      tags: [],
      title: "",
      selected_count: 0,
      uploaded_count: 0,
    };
  },
  components: {
    VueUploadMultipleImage,
  },
  methods: {
    async upload() {
      if (this.selected_count > this.uploaded_count) {
        return this.$buefy.toast.open("图片还没传好呢！");
      }
      console.log(this.uploaded);
      for (let key in this.uploaded) {
        this.files.push({
          name: key,
          image: this.uploaded[key].raw,
          compressed: this.uploaded[key].compressed,
        });
      }
      let res = await this.$http.post(
        "/api/gallery/create",
        {
          title: this.title,
          images: this.files,
          text: this.text,
          tags: this.tags,
          token: this.token,
        },
        {
          headers: {
            pass: "",
            Authorization: "Bearer " + (await localStorage.getItem("token")),
          },
        }
      );
      console.log(res.data);
      this.$router.push({
        name: "Gallery",
        query: {
          gid: res.data.gid,
        },
      });
    },
    compress(file) {
      return new Promise((resolve, reject) => {
        new Compressor(file, {
          quality: 0.6,
          maxWidth: 1000,
          maxHeight: 1000,
          success: resolve,
          error: reject,
        });
      });
    },
    async imgAdd($file, _type) {
      console.log($file);
      let _bucket = "gallery";
      let res = await this.$http.post(
        "/api/oss/presign/add",
        { bucket: _bucket },
        {
          headers: {
            pass: "",
            Authorization: "Bearer " + (await localStorage.getItem("token")),
          },
        }
      );
      console.log(res.data);
      let url;
      if (res.data.status == 20000 && res.data.url != null) {
        this.selected_count++;
        if (_type != "compressed") {
          this.$buefy.toast.open($file.name + "正在上传");
        }
        let status = await this.$http({
          url: res.data.url,
          data: $file,
          method: "PUT",
        });
        console.log(status);
        if (status.status == 200) {
          this.uploaded_count++;
          // url = res.data.url.split("?")[0];
          url = res.data.exp_url;
          if (this.uploaded[$file.name] == null) {
            this.uploaded[$file.name] = { raw: "", compressed: "" };
          }
          if (_type == "compressed") {
            this.uploaded[$file.name]["compressed"] = url;
          } else {
            this.uploaded[$file.name]["raw"] = url;
            this.$buefy.toast.open($file.name + "已经上传");
          }
        }
      } else {
      }
    },
    async uploadImageSuccess(formData, index, fileList) {
      // console.log(this.images);
      // console.log(formData);
      // console.log(index);
      // console.log(fileList);
      for (let i = 0; i < fileList.length; i++) {
        if (this.uploaded[fileList[i].name]) {
          continue;
        }
        var arr = fileList[i].path.split(","),
          mime = arr[0].match(/:(.*?);/)[1],
          bstr = atob(arr[1]),
          n = bstr.length,
          u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        let file = new File([u8arr], fileList[i].name, { type: mime });
        // console.log(file);
        this.imgAdd(file, "raw");

        let b = await this.compress(file);
        let compressed = new File([b], fileList[i].name, { type: mime });
        this.imgAdd(compressed, "compressed");

        // this.imgAdd(file);
      }

      // this.files = fileList;
      // ;

      //upload on submit

      // Upload image api
      // axios.post('http://your-url-upload', formData).then(response => {
      //   console.log(response)
      // })
    },
    beforeRemove(index, done, fileList) {
      console.log("index", index, fileList);
      var r = confirm("remove image");
      if (r == true) {
        done();
      } else {
      }
    },
    editImage(formData, index, fileList) {
      console.log("edit data", formData, index, fileList);
    },
  },
};
</script>

<style>
</style>